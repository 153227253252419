import React, { useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

export const AnimatedSlideIn = ({ children, className, parentClassName }) => {
    const [ref, inView] = useInView({
        threshold: 0.1,
    })

    const [viewed, setViewed] = useState(false)

    useEffect(() => {
        if (!viewed && inView) {
            setViewed(true)
        }
    }, [inView])

    const container = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 0.2,
                when: "beforeChildren",
                staggerChildren: 0.1,
            },
        },
    }

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
        },
    }
    return (
        <motion.div
            ref={ref}
            className={parentClassName}
            variants={container}
            initial="hidden"
            animate={viewed ? "visible" : ""}
        >
            {children.length > 0 ? (
                children.map((child, i) => (
                    <motion.div className={className} variants={item} key={i}>
                        {child}
                    </motion.div>
                ))
            ) : (
                <>{children}</>
            )}
        </motion.div>
    )
}
